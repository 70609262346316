const navRoutes = {
    'bath-property-maintenance': 'bath-property-maintenance',
    'somerset-property-maintenance': 'somerset-property-maintenance',
    'taunton-property-maintenance': 'taunton-property-maintenance',
    'keynsham-property-maintenance': 'keynsham-property-maintenance',
    'saltford-property-maintenance': 'saltford-property-maintenance',
    'paulton-property-maintenance': 'paulton-property-maintenance',
    'radstock-property-maintenance': 'radstock-property-maintenance',
    'midsomer-norton-property-maintenance': 'midsomer-norton-property-maintenance',
    'filton-property-maintenance': 'filton-property-maintenance',
    'yate-property-maintenance': 'yate-property-maintenance',
    'bridgwater-property-maintenance': 'bridgwater-property-maintenance',
    'clevedon-property-maintenance': 'clevedon-property-maintenance',
    'thornbury-property-maintenance': 'thornbury-property-maintenance',
    'nailsea-property-maintenance': 'nailsea-property-maintenance',
    'portishead-property-maintenance': 'portishead-property-maintenance',
    'longwell-green-property-maintenance': 'longwell-green-property-maintenance',
    'weston-super-mare-property-maintenance': 'weston-super-mare-property-maintenance',
    'bristol-property-maintenance': 'bristol-property-maintenance',
    'cannock-property-maintenance': 'cannock-property-maintenance',
    'bromley-property-maintenance': 'bromley-property-maintenance',
    'wells-property-maintenance': 'wells-property-maintenance',
    'shepton-mallet-property-maintenance': 'shepton-mallet-property-maintenance',
    'frome-property-maintenance': 'frome-property-maintenance',
    'chipping-sodbury-property-maintenance': 'chipping-sodbury-property-maintenance',
    'bradford-on-avon-property-maintenance': 'bradford-on-avon-property-maintenance',
    'easton-property-maintenance': 'easton-property-maintenance',
    'stapleton-property-maintenance': 'stapleton-property-maintenance',
    'southville-property-maintenance': 'southville-property-maintenance',
    'bedminster-property-maintenance': 'bedminster-property-maintenance',
    'gloucester-property-maintenance': 'gloucester-property-maintenance',
    'stroud-property-maintenance': 'stroud-property-maintenance',
    'dursley-property-maintenance': 'dursley-property-maintenance',
    'stonehouse-property-maintenance': 'stonehouse-property-maintenance',
    'tetbury-property-maintenance': 'tetbury-property-maintenance',
    'chipping-campden-property-maintenance': 'chipping-campden-property-maintenance',
    'tewkesbury-property-maintenance': 'tewkesbury-property-maintenance',
    'cirencester-property-maintenance': 'cirencester-property-maintenance',
    'northleach-property-maintenance': 'northleach-property-maintenance',
    'farnham-property-maintenance': 'farnham-property-maintenance',
    'berkeley-property-maintenance': 'berkeley-property-maintenance',
    'sharpness-property-maintenance': 'sharpness-property-maintenance',
    'chepstow-property-maintenance': 'chepstow-property-maintenance',
    'wotton-under-edge-property-maintenance': 'wotton-under-edge-property-maintenance',
    'lichfield-property-maintenance': 'lichfield-property-maintenance',
    'winterbourne-property-maintenance': 'winterbourne-property-maintenance',
    'westbury-on-severn-property-maintenance': 'westbury-on-severn-property-maintenance',
    'newent-property-maintenance': 'newent-property-maintenance',
};



export default navRoutes;

